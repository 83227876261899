
import Vue from "vue";
interface ICompanyCompanyData {
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
}
export default Vue.extend({
  name: "agencies",
  components: {
    ListAgenciesTable: () =>
      import("@/components/Agency/ListAgency/ListAgenciesTable.vue")
  },
  data(): ICompanyCompanyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false
    };
  },
  methods: {
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/admin/agencies/create`).catch(() => {});
          break;
      }
    }
  }
});
